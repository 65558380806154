// General

*{font-family: 'Titillium Web', sans-serif;}
*::selection{
  background: $primary;
  color: #ffffff;
}
.bg-grey{
  background-color: #5C5C5C !important;
}
.disabled-element{
  pointer-events: none;
  opacity: .3;
}
// Button

.btn{
  font-weight: 600;
  font-size: 16px;
  padding-left: 40px;
  padding-right: 40px;
}
.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:focus{
  color: #fff !important;
}
.btn-secondary, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus, .btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:focus{
  color: $primary !important;
}
.btn-outline-primary:hover, .btn-outline-primary:active{
  background: transparent !important;
}
.btn-primary.btn:disabled{
  border-color: #d7d7d7 !important;
  background-color: #d7d7d7 !important;
}

.bg-grey{
  background-color: #6B7888;
}
.bg-light-grey{
  background-color: $grey;
}
.icon-button{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #FFF;
  background-color: rgba(255, 255, 255, .4) ;
  border-width: 0;
  font-size: 1.1rem;
  justify-content: center;
  align-items: center;
}

.bg-red{
  background-color: #DB504A;
}
.text-red{
  color: #DB504A;
}

// Text

h1{
  color: #000000;
  font-weight: 700;
  font-size: 26px;
  margin-top: 50px;
  margin-bottom: 20px;
}
h2{
  font-weight: 600;
  font-size: 17px;
  color: #000000;
}
body{
  color: #6B7888;
  font-size: 16px;
  background: #FFF;
  min-height: 100vh;
}
.label{
  font-size: 16px;
  color: #6B7888;
}
a{
  font-weight: 800;
  &:hover{
    color: $primary;
  }
}
.link-to-confirm{
  font-weight: 800;
  padding-left: 60px;
  color: $primary;
  text-decoration: underline;
  cursor: pointer;
}
.text-right{
  text-align: right;
}
.text-regular{
  color: #6B7888;
  font-size: 14px;
  font-weight: 400;
}
.fs-20{
  font-size: 20px;
}
.fs-14{
  font-size: 14px;
}

// Form Select
.css-hzoui-control:hover{
  border-color: $primary !important;
}

.css-1nmdiq5-menu{
  z-index: 100000 !important;
}

// Modal
.modal.show .modal-dialog{
  .title-modal{
    margin-top: auto;
  }
  .modal-content{
    border: none;
    background-color: #FAFAFA;
    .modal-body{
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 0;
      .content__modal{
        height: 80vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: stretch;
        align-content: stretch;
        .container-detail{
          flex: auto;
          overflow: hidden;
          overflow-y: scroll;
          .content-detail{
            background-color: #FFF;
            border-radius: 10px;
          }
        }
        .container-actions{
          hr{
            margin-top: 0 !important;
          }
          .actions{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: end;
            gap: 15px;
          }
        }
      }
    }
  }
}

.pointer-status{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

// DatePicker
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range, .react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: $primary !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: $secondary !important;
  color: $primary !important;
}

// Tab
.tab-container{
  margin-top: 70px;
  margin-bottom: 100px;
  .tab-container-detail{
    background-color: $grey;
  }
  .nav-pills{
    .nav-link{
      background-color: $grey;
      border-radius: 0;
      border-bottom: 1px #E2E2E2 solid;
      border-left: 3px #E2E2E2 solid;
      color: #E2E2E2;
      font-weight: 800;
      cursor: default;
      .icon-check{
        display: none;
      }
      &.isValid:not(.active){
        color: $primary;
        background-color: $secondary;
        border-left-color: $primary !important;
        .icon-check{
          display: block;
        }
      }
      &.active{
        color: #000;
        background-color: $grey !important;
        border-left-color: $primary !important;
      }
   }
  }
}

// Switch

.switch{
  .form-check-input, .form-check-label{
    cursor: pointer;
  }
  .form-check-input{
    transform: scale(1.5);
    transform-origin: top left
  }
  .form-check-label{
    margin-left: 25px;
  }

}

// Calendar
.calendar-stepper .fc .fc-highlight{
  background: $primary;
}
.fc .fc-toolbar-title{
  font-size: 17px !important;
  font-weight: 600;
}
.fc-event-mirror {
  background-color: $primary;
}

/* Stile per disabilitare le celle precedenti alla data iniziale */
.fc-day.disabled-cell {
  pointer-events: none;
  background-color: #DB504A;
  color: #bbb;
  opacity: 1;
}
.fc:hover{
  cursor: pointer;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(auto, auto);
}

.cursor-pointer{
  cursor: pointer;
}

.fc .fc-bg-event{
  background-color: #DB504A;
  pointer-events: none;
  opacity: 1;

}
@media (max-width: 767px) {
  /* Stili da applicare per dispositivi con larghezza massima di 767px */
  .wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

}

// Loading
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.834);
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 4px solid;
  border-color: $primary transparent $primary transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/* Tab Orizzontale */

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: $primary;
  border-color: $primary;
  border-width: 0;
  border-bottom-width: 1px;
}

.nav-tabs .nav-link {
  color: #6B7888;
  border-color: #dee2e6;
  border-width: 0;
  border-bottom-width: 1px;
  font-weight: 600;
}
/* Slider */
.card-container {
  position: relative;
  background-color: #F5F5F5;
  margin: 0 15px;
  border-radius: 8px;
  height: auto;
  padding: 25px 25px 25px 40px;
  transition: .2s;
  cursor: pointer;
  h2{
    display: -webkit-box;           /* Required for webkit browsers (Chrome, Safari) */
    -webkit-box-orient: vertical;   /* Set the box to be vertical */
    -webkit-line-clamp: 2;          /* Limit the text to 3 lines */
    overflow: hidden;               /* Hide any overflowing text */
    text-overflow: ellipsis;        /* Adds the ellipsis (...) */
    max-height: 96.57px;
  }
  &.active {
    background-color: $primary;
    color: #FFFFFF !important;

    h2, .text-primary {
      color: #FFFFFF !important;
    }
  }
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 5px;
    height: 100%;
    border-radius: 8px 0 0 8px;
    background-color: $primary;
  }
}


.slick-next:before {
  color: #2C3E50 !important;
}

.slick-prev:before {
  color: #737E8A !important;
}

.slick-arrow{
  position: absolute !important;
  top: -25px !important;
  right: 0 !important;
}
.slick-next{
  right: 15px !important;
}
.slick-prev{
  left: calc(100% - 65px) !important;
}

.slick-dots li {
  width: 10px !important;
  height: 10px !important;
}
.slick-dots li.slick-active button:before {
  opacity: .75;
  color: $primary !important;
}

.slick-dots li button:before {
  color: $primary !important;
}

.min-3-element .slick-track{
  transform: translate3d(0px, 0px, 0px) !important;
}

.container-light-grey{
  background-color: $grey;
  border-radius: 8px;
}

.card-appointment {
  position: relative;
  background-color: #F5F5F5;
  border-radius: 8px;
  height: auto;
  transition: .2s;
  cursor: pointer;
  padding: 10px 5px 10px 20px;
  margin-bottom: 10px;
  .m-0{
    margin: 0 !important;
  }
  &.new-appointment {
    background-color: $primary;
    color: #FFFFFF;
    h2, .text-primary {
      color: #FFFFFF;
    }
    &.accepted{
      background-color: #B9D585;
      color: #000;
      h2, .text-primary {
        color: #000;
      }
    }
    &.rejected{
      background-color: #F06464;
      color: #000;
      h2, .text-primary {
        color: #000;
      }
    }
    &.pending{
      background-color: #FFCA3A;
      color: #000;
      h2, .text-primary {
        color: #000;
      }
    }
  }
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 5px;
    height: 100%;
    border-radius: 8px 0 0 8px;
  }
  &.border-primary::before {
    background-color: $primary;
  }
  &.border-blu::before {
    background-color: #108AC7;
  }
  .slick-arrow{
    top: -50px !important;
  }
  &.accepted:before{
    background-color: #B9D585;
  }
  &.rejected:before{
    background-color: #F06464;
  }
  &.pending:before{
    background-color: #FFCA3A;
  }
}

/* Table */
.table-design{
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    thead{
        background-color: #F5F5F5;
        th{
          text-align: left;
          font-weight: 600;
          color: #6B7888;
          font-size: 14px;
        }
    }
    tbody{
        tr{
        border-bottom: 1px solid #E2E2E2;
          td{
            vertical-align: middle;
            color: #000;
            padding: 12px;
            min-height: 64px;
            .table-prestazione{
              max-width: 380px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
              max-height: 96.57px;
            }
          }
          &:hover td{
            background-color: #F5F5F5;
            cursor: pointer;
          }
        }

    }
}


/* Status */

.status{
  width: 10px;
  height: 10px;
  min-width: 10px;
  border-radius: 50%;
}

.status.pending{
  background-color: #FFCA3A;
}
.status.accepted{
  background-color: #B9D585;
}
.status.rejected{
  background-color: #F06464;
}
.status.to_be_assigned {
  background-color: #108AC7;
}


.bg-primary.pending, .new-appointment.pending{
  background-color: #FFCA3A !important;
  .text-white{
    color: #000 !important;
  }
}
.bg-primary.accepted, .new-appointment.accepted{
  background-color: #B9D585 !important;
  .text-white{
    color: #000 !important;
  }
}
.bg-primary.rejected, .new-appointment.rejected{
  background-color: #F06464 !important;
  .text-white{
    color: #000 !important;
  }
}

.text-primary.pending{
  color: #FFCA3A !important;
}
.text-primary.accepted{
  color: #B9D585 !important;
}
.text-primary.rejected{
  color: #F06464 !important;
}

// Skeleton

.skeleton {
  background-color: #e0e0e0;
  height: 221.39px;
  width: -webkit-fill-available !important;
  width: fit-available;
  opacity: 1;
}

.skeleton::before {
  background-color: #e0e0e0;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}


