/* The following block can be included in a custom.scss */

$primary: #01762F;
$secondary: #F2F8F4;
$info: #00264D;
$grey: #F5F5F5;

.btn-primary{
  color: #fff;
}

.btn-secondary{
  color: $primary;
}

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';


// Tost

.toast-container {
  position: fixed;

  .toast {
    background-color: #FAFAFA;

    .toast-header {
      background-color: transparent;
    }

    border: none;
    padding: 10px;

    .pointer {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 10px;

      &.danger {
        background-color: $danger;
      }

      &.success {
        background-color: $success;
      }
    }
  }
}
